/* eslint-disable react/no-array-index-key */
import React from "react";
import {
  CbSideBySide,
  CbBackground,
  CbColumns,
  CbCta,
  CbQuotes,
  CbIconGrid,
  CbBlockQuote,
  CbCopy,
  CbImage,
  CbEmbedCode,
  ImageSpotlight,
  CbValues,
  CbJobPostings,
  CbVideo,
} from "@molecules";
import loadable from "@loadable/component";

const EntryCarousel = loadable(() => import("@organisms"), {
  resolveComponent: components => components.EntryCarousel,
});
const LocationCarousel = loadable(() => import("@organisms"), {
  resolveComponent: components => components.LocationCarousel,
});
const ImpactSpotlight = loadable(() => import("@organisms"), {
  resolveComponent: components => components.ImpactSpotlight,
});
const ReadMoreContainer = loadable(() => import("@organisms"), {
  resolveComponent: components => components.ReadMoreContainer,
});
const ImageCarousel = loadable(() => import("@organisms"), {
  resolveComponent: components => components.ImageCarousel,
});

const ContentBuilder = ({ blocks, slug, blog }) => {
  if (blocks && blocks.length) {
    const cb = [...blocks];
    return cb?.map((block, i) => {
      const { type } = block || {};
      switch (type) {
        case "sideBySide":
          return <CbSideBySide {...block} key={`contentBuilder-${i}`} />;
        case "sideBySideWithIcons":
          return <CbSideBySide {...block} key={`contentBuilder-${i}`} />;
        case "background":
          return <CbBackground {...block} key={`contentBuilder-${i}`} />;
        case "fullwidthImageNoCopy":
          return <CbBackground {...block} key={`contentBuilder-${i}`} />;
        case "backgroundWithIcons":
          return <CbBackground {...block} key={`contentBuilder-${i}`} />;
        case "threeColumnExcerpts":
          return <CbColumns {...block} key={`contentBuilder-${i}`} />;
        case "twoColumnExcerpts":
          return <CbColumns {...block} key={`contentBuilder-${i}`} />;
        case "iconCopyColumns":
          return <CbColumns {...block} key={`contentBuilder-${i}`} />;
        case "cta":
          return <CbCta {...block} key={`contentBuilder-${i}`} />;
        case "quotes":
          return <CbQuotes {...block} key={`contentBuilder-${i}`} />;
        case "blockquote":
          return (
            <CbBlockQuote {...block} key={`contentBuilder-${i}`} blog={blog} />
          );
        case "copy":
          return <CbCopy {...block} key={`contentBuilder-${i}`} blog={blog} />;
        case "image":
          return <CbImage {...block} key={`contentBuilder-${i}`} blog={blog} />;
        case "videoEmbed":
          return (
            <CbEmbedCode {...block} key={`contentBuilder-${i}`} blog={blog} />
          );
        case "carousel":
          return (
            <EntryCarousel
              slides={block?.carousel}
              key={`contentBuilder-${i}`}
            />
          );
        case "iconGrid":
          return <CbIconGrid {...block} key={`contentBuilder-${i}`} />;
        case "locationsCarousel":
          return <LocationCarousel {...block} key={`contentBuilder-${i}`} />;
        case "impact":
          return <ImpactSpotlight {...block} key={`contentBuilder-${i}`} />;
        case "fullScreenVideo":
          return <CbVideo {...block} key={`contentBuilder-${i}`} />;
        case "imageCopy":
          return <ImageSpotlight {...block} key={`contentBuilder-${i}`} />;
        case "readMore":
          return <ReadMoreContainer {...block} key={`contentBuilder-${i}`} />;
        case "background2":
          return (
            <CbBackground
              {...block}
              key={`contentBuilder-${i}`}
              noMargin
              textBottom
              biggerText
              height="tall"
            />
          );
        case "imageCarousel":
          return <ImageCarousel {...block} key={`contentBuilder-${i}`} />;
        case "valueGrid":
          return <CbValues {...block} key={`contentBuilder-${i}`} />;
        case "jobPostings":
          return <CbJobPostings {...block} key={`contentBuilder-${i}`} />;
        default:
          return null;
      }
    });
  }
  return null;
};

ContentBuilder.defaultProps = {};

ContentBuilder.propTypes = {};

export default ContentBuilder;
