import React from "react";
import { Text, Image } from "@atoms";

const HeroArticle = ({ image, title, category, postDate, author }) => {
  return (
    <header className="mt-16 md:mt-8">
      {category && (
        <Text
          variant="lg"
          className="my-6 font-bold text-blue uppercase tracking-wider"
        >
          {category}
        </Text>
      )}

      <Text variant="h1" className="mt-6 mb-10 !font-light leading-tight">
        {title}
      </Text>

      {image && (
        <div className="w-full h-96 relative">
          <Image {...image} fill eager />
        </div>
      )}

      {postDate && author && (
        <Text
          variant="lg"
          className="my-6 text-dark-gray"
        >{`${postDate} by ${author}`}</Text>
      )}
    </header>
  );
};

HeroArticle.defaultProps = {};

HeroArticle.propTypes = {};

export default HeroArticle;
