import React from "react";
import classNames from "classnames";
import { Icon, Text, Container } from "@atoms";
import { Carousel, CareerCard } from "@molecules";

const ImpactSpotlight = ({ icon, heading, subheading, impacts, other }) => {
  return (
    <section
      className={classNames(
        "min-h-10 lg:min-h-[38rem] flex flex-row relative overflow-hidden bg-light-gray"
      )}
    >
      <div className="relative z-50 mx-auto py-16 w-full">
        <Container variant="sm" className="text-center space-y-6">
          <Icon override={icon} />
          <Text variant="h2">{heading}</Text>
          <Text variant="body" className="max-w-xl mx-auto">
            {subheading}
          </Text>
        </Container>
        <Container variant="md" className="mt-6 md:mt-16">
          {impacts?.length <= 3 && (
            <div className="flex flex-col md:flex-row justify-center items-center">
              {impacts?.map((impact, i) => {
                return <CareerCard {...impact} key={`impact--${i + 1}`} />;
              })}
            </div>
          )}
          {impacts?.length > 3 && (
            <div className="flex relative -left-4 lg:left-0">
              <Carousel
                showMultiple
                showIndicators
                fullWidth={false}
                centerItems
              >
                {impacts?.map((impact, i) => {
                  return <CareerCard {...impact} key={`impact--${i + 1}`} />;
                })}
              </Carousel>
            </div>
          )}
        </Container>
      </div>
    </section>
  );
};

ImpactSpotlight.defaultProps = {};

export default ImpactSpotlight;
