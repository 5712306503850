import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { Container, Column, Text, Button, Icon } from "@atoms";
import { FormCaseStudy, FilterInsights } from "@molecules";
import { navigate } from "gatsby";
import { t } from "@utils";
import { useAppState } from "@state/state";

const CaseStudyContainer = ({ caseStudies, form, filters: _filters }) => {
  const [{ translation }] = useAppState();
  const { lang } = translation;
  // Filtering the articles
  const [filters, setFilters] = useState({
    type: 0,
  });
  const [filteredInsights, setFilteredInsights] = useState(caseStudies);
  // Show more articles on the page
  const [showMore, setShowMore] = useState(false);
  const shownStudies = showMore
    ? filteredInsights
    : filteredInsights?.slice(0, 9);

  // update filtered insights when filters change
  useEffect(() => {
    const selectedType =
      filters.type > 0
        ? Object.keys(_filters.insights)[filters.type - 1]
        : false;

    if (selectedType) {
      setFilteredInsights(
        caseStudies.filter(a => a?.type?.uid === selectedType)
      );
    } else {
      setFilteredInsights(caseStudies);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, caseStudies]);

  // Search
  const search = useRef();
  const query = useRef();

  const handleSubmit = e => {
    e.preventDefault();
    if (query.current.value) {
      navigate(`/search?q=${query.current.value}`);
    }
  };

  return (
    <>
      <section className="mt-2 text-dark-gray py-16">
        <Container variant="xl">
          <div className="px-4 md:px-8 mb-12 md:mb-16 flex flex-col md:flex-row justify-between">
            <FilterInsights model={[filters, setFilters]} options={_filters} />
            <div className="relative">
              <form
                ref={search}
                onSubmit={handleSubmit}
                className="bg-white duration-200 inset-0 h-8 flex -mt-1 border-b border-silver "
              >
                <input
                  ref={query}
                  placeholder={`${t("Search articles", lang)}...`}
                  className="flex-grow placeholder-light-silver focus:border-b-blue py-2"
                />
                <button type="button" className="ml-8 -mt-px">
                  <Icon name="search" className="w-4 text-blue fill-blue" />
                </button>
              </form>
            </div>
          </div>
          <div className="flex items-start flex-wrap">
            {shownStudies?.map((study, i) => {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <Column {...study} fullWidthMobile class="lg:pb-8" key={i} />
              );
            })}
          </div>
        </Container>
      </section>
      {form && (
        <section className="mt-2 py-16 bg-light-gray">
          <Container variant="sm">
            <div className="text-center mb-8 md:mb-16">
              <Text variant="h2" className="mb-8">
                {form?.heading}
              </Text>
              <Text
                variant="body"
                className="my-6 text-dark-gray max-w-2xl mx-auto"
              >
                {form?.subHeading}
              </Text>
            </div>
            <div className="max-w-2xl mx-auto">
              <FormCaseStudy {...form} />
            </div>
          </Container>
        </section>
      )}
      {!form && filteredInsights?.length > 9 && (
        <div
          className={classNames(
            "text-center mb-10 md:mb-12 transition duration-300 ease-in-out",
            { "opacity-0 hidden": showMore }
          )}
        >
          <Button size="lg" onClick={() => setShowMore(true)}>
            {t("Load More Articles", lang)}
          </Button>
        </div>
      )}
    </>
  );
};

CaseStudyContainer.defaultProps = {};

CaseStudyContainer.propTypes = {};

export default CaseStudyContainer;
