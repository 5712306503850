import React from "react";
import classNames from "classnames";
import { Container, Text, Column } from "@atoms";

const EventsContainer = ({ summary, events }) => {
  return (
    <section className="mt-2 text-dark-gray py-16">
      <Container variant="xl">
        {events?.length > 0 && (
          <div className={classNames("flex items-start flex-wrap")}>
            {events?.map((event, i) => {
              // eslint-disable-next-line react/no-array-index-key
              return <Column {...event} fullWidthMobile key={i} />;
            })}
          </div>
        )}
        {events?.length === 0 && (
          <Text variant="body" className="text-center">
            {summary}
          </Text>
        )}
      </Container>
    </section>
  );
};

EventsContainer.defaultProps = {};

EventsContainer.propTypes = {};

export default EventsContainer;
