import React from "react";
import classNames from "classnames";
import { Carousel, ReadMore } from "@molecules";

const ReadMoreContainer = ({ sections, color }) => {
  if (sections?.length > 1) {
    return (
      <div
        className={classNames("py-8 md:py-16", {
          "bg-light-gray": color === "gray",
          "bg-white": color === "white",
        })}
      >
        <Carousel showIndicators>
          {sections?.map((_s, i) => {
            return <ReadMore {..._s} padding={false} color={color} />;
          })}
        </Carousel>
      </div>
    );
  }
  if (sections?.length === 1) {
    return <ReadMore {...sections?.[0]} color={color} />;
  }
  return null;
};

ReadMoreContainer.defaultProps = {};

export default ReadMoreContainer;
