import React from "react";
import classNames from "classnames";
import { Button, Image, Text, Icon } from "@atoms";
import { useAppState } from "@state/state";
import { t } from "@utils";

const Hero = ({
  image,
  title,
  subtitle,
  button,
  tall,
  taller,
  center,
  hideHeroTitle,
  featured,
  label,
  embedCode,
  headingBelow,
  textPosition,
  textAlign,
  gradient,
  color,
}) => {
  const [{ translation }, dispatch] = useAppState();
  const { lang } = translation;

  const openModal = e => {
    dispatch({
      type: "openModal",
      content: embedCode ? (
        <div className="max-w-3xl lg:max-w-5xl w-full">
          <Text richText={false} className="block videoEmbed">
            {embedCode}
          </Text>
        </div>
      ) : null,
    });
  };

  return (
    <>
      <section
        className={classNames(
          "relative bg-dark-blue overflow-hidden",
          { hero: !tall && !featured },
          { "hero--tall": tall || taller },
          { "h-[37rem]": featured && !headingBelow },
          { "block h-80 md:h-[37rem]": headingBelow },
          { "text-black": color === "black" },
          { "text-white": color !== "black" }
        )}
      >
        {image && (
          <div className="absolute w-full h-full inset-0 z-10">
            <Image {...image} fill eager hero />
            <div
              className={classNames(
                "absolute w-full h-full z-20 inset-0",
                { "bg-black bg-opacity-40": color !== "black" },
                { "bg-white bg-opacity-0": color === "black" }
              )}
            />
            {gradient && (
              <>
                {(gradient === "bottom" || gradient === "both") && (
                  <div className="hidden md:block absolute w-full h-40 inset-x-0 bottom-0 z-30 bg-gradient-to-t from-white" />
                )}
                {(gradient === "side" || gradient === "both") && (
                  <div className="hidden md:block absolute h-full w-1/2 inset-y-0 left-0 z-30 bg-gradient-to-r from-white" />
                )}
              </>
            )}
          </div>
        )}

        {!hideHeroTitle && (
          <div
            className={classNames(
              "absolute inset-0 z-20 flex flex-col py-20",
              { "text-center items-center justify-end": !tall && !featured },
              { "items-end justify-center": tall },
              {
                "items-start justify-start": featured || textPosition === "top",
              },
              {
                "!items-center !justify-center":
                  !tall && !featured && textPosition === "center",
              },
              {
                "!items-start !justify-end":
                  !tall && !featured && textPosition === "bottom",
              },
              { "!text-left": !tall && !featured && textAlign === "left" },
              { "!text-right": !tall && !featured && textAlign === "right" }
            )}
          >
            <div
              className={classNames(
                "px-3 md:px-4",
                {
                  "max-w-[64rem] text-center":
                    !tall && !featured && (!textAlign || !textPosition),
                },
                { "w-full sm:w-1/2": tall },
                {
                  "!text-left w-full max-w-7xl xl:max-w-8xl mx-auto px-0 sm:px-4 lg:px-12 xl:px-8":
                    featured,
                },
                {
                  "w-full max-w-7xl xl:max-w-8xl mx-auto px-0 sm:px-4 lg:px-12 xl:px-8":
                    textAlign && textPosition,
                }
              )}
            >
              {label && (
                <Text
                  variant="base"
                  className="block animate-in fadeInUp opacity-0 leading-none mt-0 mb-6 uppercase tracking-widest font-bold"
                >
                  {label}
                </Text>
              )}
              <Text
                variant="h1"
                className={classNames(
                  "animate-in fadeInUp opacity-0 leading-none mt-0 mb-0",
                  {
                    "mb-7 md:mb-8":
                      tall && !subtitle && button?.url && button?.text,
                  },
                  { "max-w-4xl leading-tight": featured },
                  { "hidden md:block": headingBelow }
                )}
              >
                {title}
              </Text>

              {subtitle && (
                <Text
                  variant="lg"
                  className={classNames(
                    "block animate-in fadeInUp opacity-0 measure",
                    { "my-0 mt-6": !tall },
                    { "my-7 md:my-8": tall || featured },
                    { "mx-auto": center },
                    { "!text-left mx-0": featured },
                    { "hidden md:block": headingBelow }
                  )}
                >
                  {subtitle}
                </Text>
              )}

              {tall && button?.url && (
                <div className="animate-in fadeInUp opacity-0">
                  <Button size="lg" to={button?.url}>
                    <Text variant="body" className="!leading-none">
                      {t(button?.text, lang)}
                    </Text>
                  </Button>
                </div>
              )}

              {featured && button?.url && (
                <div className="animate-in fadeInUp opacity-0">
                  <Button size="lg" to={button?.url}>
                    <Text variant="body" className="!leading-none">
                      {t(button?.text, lang)}
                    </Text>
                  </Button>
                </div>
              )}
            </div>
            {featured && embedCode && (
              <div className="absolute bottom-6 right-0">
                <div className="w-full max-w-7xl xl:max-w-8xl mx-auto px-3 sm:px-4 lg:px-12 xl:px-8">
                  <Button onClick={openModal}>
                    <span className="flex flex-shrink-0 items-center justify-center">
                      <Icon name="playAlt" className="w-8 h-8 mr-4" fitHeight />
                      <Text
                        variant="body"
                        className="!leading-none tracking-widest font-bold uppercase"
                      >
                        {t("Play Video", lang)}
                      </Text>
                    </span>
                  </Button>
                </div>
              </div>
            )}
          </div>
        )}
      </section>
      {headingBelow && (
        <div className="block md:hidden px-3 md:px-4 my-8 text-center">
          <Text
            variant="h1"
            className={classNames(
              "animate-in fadeInUp opacity-0 leading-none mt-0 mb-0",
              {
                "mb-7 md:mb-8":
                  tall && !subtitle && button?.url && button?.text,
              },
              { "max-w-4xl leading-tight": featured }
            )}
          >
            {title}
          </Text>
          {subtitle && (
            <Text
              variant="lg"
              className={classNames(
                "block animate-in fadeInUp opacity-0 measure mx-auto",
                { "my-0 mt-6": !tall },
                { "my-7 md:my-8": tall || featured },
                { "mx-auto": center },
                { "text-center md:!text-left mx-auto !max-w-xl": featured }
              )}
            >
              {subtitle}
            </Text>
          )}
        </div>
      )}
    </>
  );
};

Hero.defaultProps = {};

Hero.propTypes = {};

export default Hero;
