import React from "react";
import { AboveFooterCta } from "@molecules";

const AboveFooter = ({ cards, eager }) => {
  return (
    <div className="flex flex-wrap md:flex-nowrap mt-2 mb-2 space-y-2 md:space-y-0 space-x-0 md:space-x-2">
      {cards?.map((card, i) => {
        return (
          <AboveFooterCta
            {...card}
            eager={eager}
            key={`AboveFooter--${i + 1}`}
          />
        );
      })}
    </div>
  );
};

AboveFooter.defaultProps = {};

AboveFooter.propTypes = {};

export default AboveFooter;
