import React, { useState } from "react";
import { Container, Text } from "@atoms";
import { AppLink } from "@base";

const DealerFilters = ({
  chooseCountry,
  chooseRegion,
  prefilteredCopy,
  countries,
  dealers,
}) => {
  const [activeSearch, setActiveSearch] = useState({
    country: "none",
    region: "none",
  });
  const regions = dealers
    ?.filter(dealer => dealer?.country === activeSearch?.country)
    .map(dealer => dealer?.region)
    .sort()
    .filter((item, pos, self) => {
      // eslint-disable-next-line eqeqeq
      return self.indexOf(item) == pos;
    });
  const regionDealers = dealers?.filter(
    dealer => activeSearch?.region === dealer?.region
  );
  const dealersToShow =
    activeSearch?.region === "none" ? dealers : regionDealers;

  return (
    <section className="py-10 md:py-16">
      <Container variant="md">
        <div className="grid grid-cols-1 md:grid-cols-[.5fr,1fr] gap-12">
          {/* Filters */}
          <div>
            <div className="w-full md:max-w-sm mb-10">
              <Text variant="h3" className="mb-4">
                {chooseCountry}
              </Text>
              <div className="after:text-blue relative form__selectContainer">
                <select
                  className="px-3 py-4 pr-6 bg-light-gray w-full cursor-pointer appearance-none"
                  onChange={e => {
                    setActiveSearch({
                      region: "none",
                      country: e?.target?.value,
                    });
                  }}
                >
                  <option value="none">{chooseCountry}</option>
                  {countries?.map((country, i) => {
                    return (
                      // eslint-disable-next-line react/no-array-index-key
                      <option value={country} key={`country--${i}`}>
                        {country}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            {activeSearch?.country !== "none" && (
              <div className="w-full md:max-w-sm">
                <Text variant="h3" className="mb-4">
                  {chooseRegion}
                </Text>
                <div className="after:text-blue relative form__selectContainer">
                  <select
                    className="px-3 py-4 pr-6 bg-light-gray w-full cursor-pointer appearance-none"
                    onChange={e => {
                      setActiveSearch({
                        ...activeSearch,
                        region: e?.target?.value,
                      });
                    }}
                  >
                    <option value="none">{chooseRegion}</option>
                    {regions?.map((region, i) => {
                      if (region !== "") {
                        return (
                          // eslint-disable-next-line react/no-array-index-key
                          <option value={region} key={`region--${i}`}>
                            {region}
                          </option>
                        );
                      }
                      return null;
                    })}
                  </select>
                </div>
              </div>
            )}
          </div>

          {/* Content */}
          <div>
            {activeSearch?.country === "none" && (
              <Text variant="body" className="max-w-xs mx-auto">
                {prefilteredCopy}
              </Text>
            )}
            {activeSearch?.country !== "none" && (
              <div className="grid grid-cols-2 items-start gap-8">
                {dealersToShow?.map((dealer, i) => {
                  if (dealer?.country === activeSearch?.country) {
                    return (
                      <div className="border-b flex flex-col p-4">
                        <Text variant="h4" className="mb-4 leading-normal">
                          {dealer?.title}
                        </Text>
                        <Text variant="sm">{dealer?.address}</Text>
                        {dealer?.telephone && (
                          <AppLink
                            to={`tel:${dealer?.telephone}`}
                            className="!text-blue hover:!text-black my-2"
                          >
                            <Text>{dealer?.telephone}</Text>
                          </AppLink>
                        )}
                        {dealer?.website && (
                          <AppLink
                            to={dealer?.website}
                            className="!text-blue hover:!text-black mt-2"
                          >
                            <Text>Visit Dealer Website</Text>
                          </AppLink>
                        )}
                      </div>
                    );
                  }
                  return null;
                })}
              </div>
            )}
          </div>
        </div>
      </Container>
    </section>
  );
};

DealerFilters.defaultProps = {};

DealerFilters.propTypes = {};

export default DealerFilters;
