import React from "react";
import { Image, Icon } from "@atoms";
import { Carousel } from "@molecules";

const LeftArrow = () => (
  <div className="rotate-180 w-8 h-8 -translate-x-6 sm:-translate-x-24 lg:-translate-x-32 hidden md:block">
    <Icon name="arrowAlt" className="w-8 h-8" fitHeight />
  </div>
);

const RightArrow = () => (
  <div className="translate-x-6 sm:translate-x-24 lg:translate-x-32 hidden md:block">
    <Icon name="arrowAlt" className="w-8 h-8" fitHeight />
  </div>
);

const CarouselImage = ({ image }) => (
  <div className="px-0 md:px-3 mb-4">
    <Image {...image} />
  </div>
);

const ImageCarousel = ({ images }) => {
  return (
    <section className="py-8 md:py-16 bg-light-gray">
      <div className="w-full max-w-5xl xl:max-w-6xl mx-auto px-0 sm:px-4 lg:px-12 xl:px-8">
        <div className="w-full">
          {images && (
            <Carousel
              showIndicators
              showMultiple
              maxVisible={1}
              nextButton={RightArrow}
              prevButton={LeftArrow}
            >
              {images?.map((_i, i) => {
                return <CarouselImage image={_i} />;
              })}
            </Carousel>
          )}
        </div>
      </div>
    </section>
  );
};

ImageCarousel.defaultProps = {};

export default ImageCarousel;
