import React from "react";
import { Carousel, CarouselSlide } from "@molecules";

const EntryCarousel = ({ slides }) => {
  return (
    <section className="bg-light-gray py-12 lg:py-16 my-2">
      <Carousel maxVisible={1} showIndicators>
        {slides?.map((slide, i) => {
          return <CarouselSlide {...slide} key={`slide--${i + 1}`} />;
        })}
      </Carousel>
    </section>
  );
};

EntryCarousel.defaultProps = {};

EntryCarousel.propTypes = {};

export default EntryCarousel;
