import React, { useState, useRef } from "react";
import { Container, Text } from "@atoms";
import { AppLink } from "@base";
import loadable from "@loadable/component";
import tailwindConfig from "@tailwind";
import { useWindowSize } from "@utils";

const MapGL = loadable(() => import("react-map-gl"));
const Source = loadable(() => import("react-map-gl"), {
  resolveComponent: components => components.Source,
});
const Layer = loadable(() => import("react-map-gl"), {
  resolveComponent: components => components.Layer,
});
const MapRef = loadable(() => import("react-map-gl"), {
  resolveComponent: components => components.MapRef,
});
const Popup = loadable(() => import("react-map-gl"), {
  resolveComponent: components => components.Popup,
});
const NavigationControl = loadable(() => import("react-map-gl"), {
  resolveComponent: components => components.NavigationControl,
});

const MapContainer = ({ dealers, geojson }) => {
  const { screens, extend } = tailwindConfig.theme;
  const mapRef = useRef(MapRef);
  const [info, setInfo] = useState(null);
  const { innerWidth: windowSize } = useWindowSize();
  const convertToNumber = string => {
    return parseFloat(string.replace("px", ""));
  };
  // eslint-disable-next-line no-unused-vars
  const [viewport, setViewport] = useState({
    width: "100%",
    height: "100%",
    latitude: windowSize > convertToNumber(screens.md) ? -1 : 39.381266,
    longitude: windowSize > convertToNumber(screens.md) ? 15 : -95.712891,
    zoom: windowSize > convertToNumber(screens.md) ? 1.5 : 2.5,
    bearing: 0,
    pitch: 0,
  });

  const layerStyle = {
    id: "Layer_CertifiedDealers",
    source: "Source_CertifiedDealers",
    type: "symbol",
    filter: ["!", ["has", "point_count"]],
    layout: {
      "icon-image": "custom-marker",
      "icon-size": 1, // making this larger makes this blurry for some reason. https://www.mapbox.com/help/studio-troubleshooting-svg/
      "icon-allow-overlap": true, // this fix the issue where markers were showing/hiding at different zoom levels. ughhhhhhhh
    },
  };

  const layerStyleCluster = {
    id: "Layer_CertifiedDealers_Circle",
    source: "Source_CertifiedDealers",
    type: "circle",
    filter: ["has", "point_count"],
    paint: {
      "circle-color": extend.colors.blue.DEFAULT,
      "circle-radius": 15,
      "circle-opacity": 0.4,
    },
  };

  const layerStyleClusterCount = {
    id: "Layer_CertifiedDealers_Count",
    source: "Source_CertifiedDealers",
    type: "symbol",
    filter: ["has", "point_count"],
    layout: {
      "icon-size": 1.5, // making this larger makes this blurry for some reason. https://www.mapbox.com/help/studio-troubleshooting-svg/
      "icon-allow-overlap": true,
      "text-field": "{point_count_abbreviated}",
      "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
      "text-size": 14,
    },
  };

  const navStyle = {
    top: 0,
    right: 0,
    padding: "10px",
  };

  const onClick = e => {
    const feature = e?.features?.[0];

    if (feature?.properties?.title) {
      // react-map-gl was updated and no longer requires the viewport state
      // to be updated on each click, now there's an flyTo() function that
      // handles all of that
      mapRef.current?.flyTo({
        center: feature?.geometry?.coordinates,
        duration: 2000,
        zoom: 4,
        curve: 1,
      });

      const website =
        feature?.properties?.website === "null"
          ? null
          : feature?.properties?.website;
      const telephone =
        feature?.properties?.telephone === "null"
          ? null
          : feature?.properties?.telephone;
      setInfo({
        ...feature?.properties,
        telephone,
        website,
        longitude: feature?.geometry?.coordinates?.[0],
        latitude: feature?.geometry?.coordinates?.[1],
      });
    }
  };

  return (
    <section className="pb-12 md:pb-16">
      <Container variant="xl">
        <div className="pb-[56.5%] relative">
          <div className="absolute inset-0 h-full w-full">
            <MapGL
              initialViewport={viewport}
              initialViewState={viewport}
              style={{ width: "100%", height: "100%" }}
              ref={mapRef}
              mapStyle="mapbox://styles/ryandesignbycosmic/cj8m11y9z1jgq2rmiwau6iif0"
              mapboxAccessToken={process.env.GATSBY_MAPBOX_TOKEN}
              scrollZoom={false}
              onClick={onClick}
              interactiveLayerIds={["Layer_CertifiedDealers"]}
            >
              <Source
                id="my-data"
                type="geojson"
                data={geojson}
                cluster={windowSize < convertToNumber(screens.md)}
                clusterRadius={30}
              >
                <Layer {...layerStyle} />
                <Layer {...layerStyleCluster} />
                <Layer {...layerStyleClusterCount} />
              </Source>
              {info && (
                <Popup
                  latitude={info?.latitude}
                  longitude={info?.longitude}
                  closeButton
                  closeOnClick={false}
                  onClose={() => setInfo(null)}
                >
                  <Text variant="h5" className="font-bold mb-4">
                    {info?.title}
                  </Text>
                  <Text variant="sm">{info?.address?.split(/\n|\\n/gm)}</Text>
                  {info?.telephone && (
                    <AppLink
                      to={`tel:${info?.telephone}`}
                      className="!text-blue hover:!text-black my-2"
                    >
                      <Text>{info?.telephone}</Text>
                    </AppLink>
                  )}
                  {info?.website && (
                    <AppLink
                      to={info?.website}
                      className="!text-blue hover:!text-black mt-2"
                    >
                      <Text>Visit Dealer Website</Text>
                    </AppLink>
                  )}
                </Popup>
              )}
              <NavigationControl position="top-right" style={navStyle} />
            </MapGL>
          </div>
        </div>
      </Container>
    </section>
  );
};

MapContainer.defaultProps = {};

MapContainer.propTypes = {};

export default MapContainer;
