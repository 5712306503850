import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { Image, Text, Container, Icon } from "@atoms";

const LeftArrow = () => (
  <div className="rotate-180">
    <Icon name="arrowAlt" className="w-8 h-8 text-white" fitHeight />
  </div>
);

const RightArrow = () => (
  <div className="">
    <Icon name="arrowAlt" className="w-8 h-8 text-white" fitHeight />
  </div>
);

const LocationCarousel = ({ label, heading, locations }) => {
  const [location, setLocation] = useState(locations?.[0]?.location || "");
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    setLocation(locations[currentSlide]?.location);
  }, [currentSlide]);

  return (
    <section className="py-8 md:py-16 relative h-[100vw] md:h-[42rem] lg:h-[50rem]">
      <div className="absolute z-20 top-0 left-0 w-full h-full bg-gradient-to-br from-[rgba(0,0,0,0.4)]" />
      <Container variant="md" className="text-white relative z-50 ">
        <Text variant="sm" className="mb-4 font-semibold">
          {label}
        </Text>
        <Text variant="h2" className="max-w-xs">
          {heading}
        </Text>
        <Text variant="sm" className="mt-4 font-semibold">
          {location}
        </Text>
      </Container>

      <div className="w-full absolute z-50 h-10 inset-0 m-auto">
        <Container variant="xl" className="flex justify-between ">
          <button
            type="button"
            onClick={() => {
              setCurrentSlide(prevState => prevState - 1);
            }}
            className={classNames({
              "opacity-0 invisible": currentSlide === 0,
            })}
          >
            <LeftArrow />
          </button>
          <button
            type="button"
            onClick={() => {
              setCurrentSlide(prevState => prevState + 1);
            }}
            className={classNames({
              // eslint-disable-next-line no-unsafe-optional-chaining
              "opacity-0 invisible": currentSlide === locations?.length - 1,
            })}
          >
            <RightArrow />
          </button>
        </Container>
      </div>

      {locations?.map((_l, i) => {
        return (
          <div
            key={`location--${i + 1}`}
            className={classNames(
              "absolute w-full h-full inset-0 z-10 transition duration-500",
              {
                "opacity-100": currentSlide === i,
                "opacity-0": currentSlide !== i,
              }
            )}
          >
            <Image {..._l?.image} fill />
          </div>
        );
      })}

      {locations.length > 1 && (
        <div className="absolute bottom-0 w-full z-50 bg-gradient-to-t from-[rgba(0,0,0,0.15)]">
          <Container
            variant="md"
            className="flex gap-3 items-center justify-center my-8 md:my-16"
          >
            {locations.map((_l, i) => {
              return (
                <button
                  key={`location-button--${i + 1}`}
                  type="button"
                  className={classNames(
                    "w-full h-1 ",
                    { "opacity-100 bg-blue": currentSlide === i },
                    { "opacity-75 bg-white": currentSlide !== i }
                  )}
                  aria-label={`Go to location ${i + 1}`}
                  onClick={() => setCurrentSlide(i)}
                />
              );
            })}
          </Container>
        </div>
      )}
    </section>
  );
};

LocationCarousel.defaultProps = {};

export default LocationCarousel;
