/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useRef, useEffect } from "react";
import classNames from "classnames";
import { Text, Container, Image, Button, DropDown } from "@atoms";
import { Carousel } from "@molecules";
import { useAppState } from "@state/state";
import { t as tran, convertToMoney } from "@utils";

const PricingCalculator = ({
  heading,
  images,
  link,
  displaysHeading,
  displays,
  transmittersHeading,
  transmitters,
  packages,
  asteriskText,
  includes,
  financeTabText,
  buyTabText,
  buyPriceHeading,
  financePriceHeading,
  financeHeading,
  financeSubheading,
  financePlans,
  hideCalculator,
  hideFinance,
  defaultCalculatorTab,
  openSummary,
  summary,
  summaryFinanceHeading,
  summaryBuyHeading,
  copyrightText,
  showTradeIn,
  tradeInText,
}) => {
  const [{ translation }, dispatch] = useAppState();
  const { lang } = translation;
  const startingTab =
    hideFinance || hideCalculator ? "buy" : defaultCalculatorTab;

  const [activePackage, setActivePackage] = useState(packages?.[0] || {});
  const [transmitter, setTransmitter] = useState(
    transmitters?.[0]?.uid || null
  );
  const [display, setDisplay] = useState(displays?.[0]?.uid || null);

  const [checkedTradeIn, setCheckedTradeIn] = useState(false);

  const [activeTab, setActiveTab] = useState(startingTab);
  const buyTab = hideCalculator || hideFinance || activeTab === "buy";
  // const finTab = !hideCalculator || !hideFinance || activeTab === "finance";
  const [plan, setPlan] = useState(financePlans?.[0]?.months || 60);
  const [interest, setInterest] = useState(financePlans?.[0]?.interest);

  // Have the transmitters and displays use a different state
  // so that the source array can stay as simple as possible
  const [transmitterDropdown, setTransmitterDropdown] = useState(0);
  const [displayDropdown, setDisplayDropdown] = useState(0);

  const transmitterUids = transmitters?.map(p => p?.uid);
  const displayUids = displays?.map(p => p?.uid);

  const useDropdowns = transmitters?.length >= 5 || displays?.length >= 5;

  const urlHasParams =
    typeof window !== "undefined"
      ? new URL(window.location.href).searchParams.has("transmitter") ||
        new URL(window.location.href).searchParams.has("display") ||
        new URL(window.location.href).searchParams.has("tab") ||
        new URL(window.location.href).searchParams.has("plan")
      : null;
  const url =
    typeof window !== "undefined"
      ? window.location.origin + window.location.pathname
      : null;
  const calculatorContainer = useRef();

  const additionalIncludes = []
    ?.concat(includes)
    ?.concat(activePackage?.additionalIncludes)
    ?.filter(i => i);

  const financingCalculator = (price, financingDuration, financingInterest) => {
    let factors;
    if (price > 15000) {
      factors = {
        36: 0.0299485,
        48: 0.0230067,
        60: 0.0188483,
      };
    } else {
      factors = {
        36: 0.0313133,
        48: 0.0243895,
        60: 0.0202525,
      };
    }
    return convertToMoney(
      price * (financingInterest || factors[financingDuration])
    );
  };

  const calculateTradeIn = (base, discount, options) => {
    let price;
    const { convertToDollars } = options || {};

    if (!checkedTradeIn) {
      price = base;
    } else {
      price = base - discount;
    }

    return convertToDollars ? convertToMoney(price) : price;
  };

  useEffect(() => {
    if (urlHasParams) {
      const params = new URL(window.location.href).searchParams;
      const urlTransmitter = params.get("transmitter");
      const urlDisplay = params.get("display");
      const urlTab = params.get("tab");
      const urlPlan = params.get("plan");

      // Since the dropdowns use the index instead of the uid it needs
      // to be set if dropdowns exist of else the content wont update
      if (useDropdowns) {
        setTransmitterDropdown(transmitterUids.indexOf(urlTransmitter));
        setDisplayDropdown(displayUids.indexOf(urlDisplay));
      }

      // eslint-disable-next-line no-console
      console.log({
        urlTransmitter,
        urlDisplay,
        urlTab,
        urlPlan,
      });

      setTransmitter(urlTransmitter);
      setDisplay(urlDisplay);
      setActiveTab(urlTab);
      setPlan(parseFloat(urlPlan));

      calculatorContainer.current.scrollIntoView({
        block: "center",
        inline: "nearest",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlHasParams]);

  useEffect(() => {
    if (!hideCalculator && display?.length && transmitter?.length) {
      if (useDropdowns) {
        if (typeof transmitterDropdown === "number") {
          setTransmitter(transmitterUids[transmitterDropdown]);
        }

        if (typeof displayDropdown === "number") {
          setDisplay(displayUids[displayDropdown]);
        }
      }

      const current = packages?.find((p, i) => {
        const { options } = p;
        const { calcDisplay, calcTransmitter } = options;
        const hitTransmitter = calcTransmitter?.uid === transmitter;
        const hitDisplay = calcDisplay?.uid === display;

        return hitDisplay && hitTransmitter;
      });
      setActivePackage(current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transmitter, display, transmitterDropdown, displayDropdown]);

  useEffect(() => {}, [hideFinance, hideCalculator, defaultCalculatorTab]);

  useEffect(() => {
    if (openSummary) {
      const financePrice = financingCalculator(
        calculateTradeIn(
          activePackage?.packagePrice,
          activePackage?.tradeInDiscount
        ),
        plan,
        interest
      );
      const buyPice = calculateTradeIn(
        activePackage?.packagePrice,
        activePackage?.tradeInDiscount,
        { convertToDollars: true }
      );

      const productSummary = {
        ...summary,
        productImage: images?.[0],
        financeHeading,
        buyPriceHeading,
        price: activeTab === "finance" ? `${financePrice}*` : `${buyPice}*`,
        priceHeading:
          activeTab === "finance" ? summaryFinanceHeading : summaryBuyHeading,
        priceSubheading:
          activeTab === "finance" ? buyPriceHeading : financeHeading,
        priceSecondary:
          activeTab === "finance"
            ? `${buyPice}*`
            : financePlans?.filter(p => p?.months === plan)?.[0]?.summaryText,
        includes: additionalIncludes,
        transmittersDescription:
          transmitters?.[transmitterUids?.indexOf(transmitter)]
            ?.metaDescription,
        displaysDescription:
          displays?.[displayUids?.indexOf(display)]?.metaDescription,
        shareUrl: `${url}?transmitter=${transmitter}&display=${display}&tab=${activeTab}&plan=${plan}`,
        showFinanceText: hideFinance || hideCalculator,
        tradeIn: checkedTradeIn ? tradeInText : null,
      };

      dispatch({
        type: "updateSummary",
        content: productSummary,
      });
    }
  }, [
    plan,
    activeTab,
    activePackage,
    checkedTradeIn,
    transmitter,
    display,
    transmitterDropdown,
    displayDropdown,
  ]);

  const showSummary = e => {
    dispatch({
      type: "openSummary",
    });
  };

  return (
    <section ref={calculatorContainer} className="mt-2 text-black py-8">
      <Container variant="lg">
        <div className="grid grid-cols-12 gap-8">
          {/* Carousel */}
          <div className="col-span-full md:col-span-6">
            <Carousel maxVisible={1} thumbnails={images}>
              {images?.map(image => {
                return (
                  <div className="pointer-events-none">
                    <Image {...image} />
                  </div>
                );
              })}
            </Carousel>
          </div>

          <div className="col-span-full md:col-span-6">
            {heading && (
              <div
                className={classNames(
                  "px-4",
                  { "mb-0": !transmitters?.length && !displays?.length },
                  { "mb-8": !!transmitters?.length && !!displays?.length }
                )}
              >
                <Text variant="h3">{heading}</Text>
              </div>
            )}

            {!hideCalculator && !hideFinance && (
              <div className="flex justify-center border-b border-light-silver mb-8 space-x-6 mx-4">
                <button
                  type="button"
                  onClick={() => setActiveTab("finance")}
                  className={classNames(
                    "py-3 px-6 w-28 border-b-4",
                    { "border-blue": activeTab === "finance" },
                    { "border-transparent": activeTab !== "finance" }
                  )}
                >
                  <Text variant="lg">{financeTabText}</Text>
                </button>

                <button
                  type="button"
                  onClick={() => setActiveTab("buy")}
                  className={classNames(
                    "py-3 px-6 w-28 border-b-4",
                    { "border-blue": activeTab === "buy" },
                    { "border-transparent": activeTab !== "buy" }
                  )}
                >
                  <Text variant="lg">{buyTabText}</Text>
                </button>
              </div>
            )}

            {!hideCalculator && (
              <div
                className={classNames(
                  "grid grid-cols-1 sm:grid-cols-2 gap-8 mx-4",
                  {
                    "border-b border-light-silver mb-6":
                      transmitters?.length || displays?.length,
                  }
                )}
              >
                <div>
                  {!!transmitters?.length && (
                    <Text variant="lg" className="text-dark-grey mb-4">
                      {transmittersHeading}
                    </Text>
                  )}
                  {useDropdowns && (
                    <div className="mb-16">
                      <DropDown
                        model={[transmitterDropdown, setTransmitterDropdown]}
                        options={transmitters?.map(t => t.title)}
                        placeholder={transmitters?.map(t => t.title)[0]}
                      />
                    </div>
                  )}
                  {!useDropdowns && !hideCalculator && (
                    <ul className="list-none pb-8">
                      {transmitters?.map((t, i) => {
                        return (
                          // eslint-disable-next-line react/no-array-index-key
                          <li className="mb-2" key={`transmitter--${i}`}>
                            <label
                              className="flex items-baseline radio"
                              htmlFor={t?.uid}
                            >
                              <input
                                id={t?.uid}
                                type="radio"
                                name="transmitter"
                                value={t?.uid}
                                checked={t?.uid === transmitter}
                                onChange={e => {
                                  setTransmitter(e?.target?.value);
                                }}
                                className="invisible"
                              />
                              <span className="control-indicator" />
                              <Text
                                variant="body"
                                className="ml-4 text-dark-gray"
                              >
                                {t?.title}
                              </Text>
                            </label>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </div>

                <div>
                  {!!displays?.length && (
                    <Text variant="lg" className="text-dark-grey mb-4">
                      {displaysHeading}
                    </Text>
                  )}
                  {useDropdowns && (
                    <div className="mb-16">
                      <DropDown
                        model={[displayDropdown, setDisplayDropdown]}
                        options={displays?.map(t => t.title)}
                        placeholder={displays?.map(t => t.title)[0]}
                      />
                    </div>
                  )}
                  {!useDropdowns && (
                    <ul className="list-none pb-8">
                      {displays?.map((d, i) => {
                        return (
                          // eslint-disable-next-line react/no-array-index-key
                          <li className="mb-2" key={`display--${i}`}>
                            <label
                              className="flex items-baseline radio"
                              htmlFor={d?.uid}
                            >
                              <input
                                id={d?.uid}
                                type="radio"
                                name="display"
                                value={d?.uid}
                                checked={d?.uid === display}
                                onChange={e => {
                                  setDisplay(e?.target?.value);
                                }}
                                className="invisible"
                              />
                              <span className="control-indicator" />
                              <Text
                                variant="body"
                                className="ml-4 text-dark-gray"
                              >
                                {d?.title}
                              </Text>
                            </label>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </div>
              </div>
            )}

            {!!packages?.length && (
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-0 sm:gap-8">
                {activePackage?.additionalIncludes?.length > 0 && (
                  <div>
                    {!buyTab && (
                      <div className="px-4">
                        <div className="my-4">
                          <Text variant="lg" className="mb-2">
                            {financeHeading}
                          </Text>
                          <Text variant="sm" className="text-dark-gray">
                            {`${financeSubheading} - ${convertToMoney(
                              activePackage?.packagePrice
                            )}`}
                          </Text>
                        </div>
                        <ul className="list-none pb-8">
                          {financePlans?.map((p, i) => {
                            return (
                              <li className="mb-2" key={`plan--${p?.months}`}>
                                <label
                                  className="flex items-baseline radio"
                                  htmlFor={`financePlans--${p?.months}`}
                                >
                                  <input
                                    id={`financePlans--${p?.months}`}
                                    type="radio"
                                    name="plan"
                                    value={p?.months}
                                    checked={p?.months === plan}
                                    onChange={e => {
                                      setPlan(parseFloat(e?.target?.value));
                                      setInterest(parseFloat(p?.interest));
                                    }}
                                    className="invisible"
                                  />
                                  <span className="control-indicator" />
                                  <Text
                                    variant="body"
                                    className="ml-4 text-dark-gray"
                                  >{`${p?.months} ${p?.monthText}`}</Text>
                                </label>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    )}

                    {buyTab && (
                      <>
                        <Text variant="lg" className="text-dark-grey p-4">
                          {`${tran("Includes", lang)}:`}
                        </Text>
                        <ul className="list-disc text-dark-gray pl-8 pr-4">
                          {includes?.map(item => {
                            return (
                              <li className="pl-2 pb-2">
                                <Text variant="sm">{item}</Text>
                              </li>
                            );
                          })}
                          {activePackage?.additionalIncludes?.map(item => {
                            return (
                              <li className="pl-2 pb-2">
                                <Text variant="sm">{item}</Text>
                              </li>
                            );
                          })}
                        </ul>
                      </>
                    )}

                    <Text
                      variant="sm"
                      className="mt-8 px-4 !inline-block sm:!hidden text-dark-gray italic"
                    >
                      <span>
                        *{" "}
                        {asteriskText ||
                          "Price may vary based on regional equipment requirements"}
                      </span>
                    </Text>
                  </div>
                )}

                {/* Pricing */}
                {packages?.length > 0 && (
                  <div className="order-first sm:order-1">
                    <div className="flex flex-col w-full mb-0 sm:mb-8 px-4 sm:px-0">
                      <div className="mb-4 flex flex-col">
                        <Text
                          variant="body"
                          className="text-4xl lg:text-5xl inline-block text-blue tracking-tight"
                        >
                          {buyTab && (
                            <span>
                              {calculateTradeIn(
                                activePackage?.packagePrice,
                                activePackage?.tradeInDiscount,
                                { convertToDollars: true }
                              )}
                              <sup>*</sup>
                            </span>
                          )}
                          {!buyTab && (
                            <span>
                              {financingCalculator(
                                calculateTradeIn(
                                  activePackage?.packagePrice,
                                  activePackage?.tradeInDiscount
                                ),
                                plan,
                                interest
                              )}
                              /mo.<sup>*</sup>
                            </span>
                          )}
                        </Text>
                        <Text variant="body" className="text-dark-gray">
                          {buyTab
                            ? buyPriceHeading
                            : financePriceHeading?.replace(/{([^]*)}/g, plan)}
                        </Text>
                      </div>

                      {showTradeIn && tradeInText && (
                        <div className="mb-4">
                          <input
                            type="checkbox"
                            id="showTradeInNumbers"
                            name="showTradeInNumbers"
                            checked={checkedTradeIn}
                            onChange={() => setCheckedTradeIn(!checkedTradeIn)}
                            className="cursor-pointer"
                          />
                          <label
                            htmlFor="showTradeInNumbers"
                            className="cursor-pointer ml-2"
                          >
                            {tradeInText}
                          </label>
                        </div>
                      )}

                      <div>
                        {link?.url && (
                          <Button
                            size="lg"
                            to={openSummary ? null : link?.url}
                            onClick={() => {
                              if (openSummary) {
                                showSummary();
                              }
                            }}
                            className="w-full"
                          >
                            <Text variant="body" className="!leading-none">
                              {link?.text}
                            </Text>
                          </Button>
                        )}
                      </div>
                    </div>
                    <Text
                      variant="sm"
                      className="mt-8 hidden sm:!inline text-dark-gray italic"
                    >
                      *{" "}
                      {asteriskText ||
                        "Price may vary based on regional equipment requirements"}
                    </Text>
                  </div>
                )}
              </div>
            )}

            {copyrightText && (
              <div className="border-t border-light-silver mt-4 pt-4">
                <Text
                  variant="sm"
                  className="mt-8 !inline text-dark-gray italic"
                >
                  {copyrightText}
                </Text>
              </div>
            )}
          </div>
        </div>
      </Container>
    </section>
  );
};

PricingCalculator.defaultProps = {};

PricingCalculator.propTypes = {};

export default PricingCalculator;
