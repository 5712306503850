import React, { useState } from "react";
import { CareerCard, Carousel, SideHeadline } from "@molecules";
import { World } from "@svg";

const CareersMap = ({ heading, subheading, offices }) => {
  const [highlighted, setHighlighted] = useState(
    offices?.[0]?.countryCode || "US"
  );

  return (
    <div className="w-full py-16">
      <SideHeadline
        heading={heading}
        subheading={subheading}
        color="black"
        className="pb-16 md:pb-0"
      />

      <div className="max-w-5xl w-full mx-auto mt-4">
        <World highlightedState={[highlighted, setHighlighted]} />
      </div>

      <div className="flex justify-center py-2 relative">
        <div className="absolute left-0 inset-y-0 w-20 h-full bg-gradient-to-r from-white z-50 pointer-events-none" />
        <div className="absolute right-0 inset-y-0 w-20 h-full bg-gradient-to-l from-white z-50 pointer-events-none" />
        {offices?.length && (
          <Carousel showMultiple maxVisible={1} className="mt-4">
            {offices?.map((office, i) => {
              return (
                <CareerCard
                  {...office}
                  idx={i}
                  highlightedState={[highlighted, setHighlighted]}
                />
              );
            })}
          </Carousel>
        )}
      </div>
    </div>
  );
};

CareersMap.defaultProps = {};

export default CareersMap;
